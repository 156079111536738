'use client';

import { ApiUrl } from '@/constants';
import type { SavedeskPage as SavedeskPageCmsData } from '@/types/cms/startPageCmsData';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import ConfirmationView from '@/app/_lib/PageWrappers/SlugPages/Savedesk/ConfirmationView/ConfirmationView';
import ErrorView from '@/app/_lib/PageWrappers/SlugPages/Savedesk/SavedeskError/SavedeskError';
import SavedeskOfferView from '@/app/_lib/PageWrappers/SlugPages/Savedesk/SavedeskOfferView';

import SavedeskLoader from '@/app/_lib/PageWrappers/SlugPages/Savedesk/SavedeskLoader/SavedeskLoader';

type SavedeskOffer = {
  accessToken: string;
  subscription: {
    maskedPhoneNumber: string;
    name: string;
    offerDuration: string;
    offerPrice: string;
    ordPrice: string;
    status: string;
    voucherExpirationDate: string;
  };
};

type OfferResponse = {
  data?: SavedeskOffer;
};

type SavedeskPageProps = {
  data: SavedeskPageCmsData;
};

const SavedeskPage = (props: SavedeskPageProps) => {
  const { data } = props;
  const [offer, setOffer] = useState<OfferResponse>();
  const [currentPage, setCurrentPage] = useState('');
  const searchParams = useSearchParams();
  const subscriptionId = searchParams?.get('s') || '';
  const userId = searchParams?.get('u') || '';

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [currentPage]);

  useEffect(() => {
    const getSavedeskOffer = async () => {
      const res = await fetch(`${ApiUrl.webClientApi}/savedesk?s=${subscriptionId}&u=${userId}`);
      if (res.status === 200) {
        const json = await res.json();
        setOffer(json);
        setCurrentPage('OfferView');
      } else {
        setCurrentPage('ErrorView');
      }
    };
    if (typeof subscriptionId === 'undefined' || typeof userId === 'undefined') {
      setCurrentPage('ErrorView');
    } else {
      getSavedeskOffer();
    }
  }, [subscriptionId, userId]);

  switch (currentPage) {
    case 'ErrorView':
      return <ErrorView data={data} />;
    case 'ConfirmationView':
      return <ConfirmationView data={data} offer={offer?.data} />;
    case 'OfferView':
      return (
        <SavedeskOfferView
          data={data}
          id={subscriptionId as string}
          setCurrentPage={setCurrentPage}
          offer={offer?.data}
        />
      );
    case '':
    default:
      return <SavedeskLoader />;
  }
};
export default SavedeskPage;

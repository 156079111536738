type DateStringObject = {
  year: number;
  month: string;
  day: string;
  hour: number;
  minutes: number;
};

const iso8061ToString = (isoString: string): [string, DateStringObject] => {
  const date = new Date(isoString);

  const iso8061toStringObject = {
    year: date.getFullYear(),
    month: `0${date.getMonth() + 1}`.slice(-2),
    day: `0${date.getDate()}`.slice(-2),
    hour: date.getHours(),
    minutes: date.getMinutes(),
  };

  const iso8061toStringFormatted = `${iso8061toStringObject.year}-${iso8061toStringObject.month}-${iso8061toStringObject.day} kl ${iso8061toStringObject.hour}:${iso8061toStringObject.minutes}`;

  return [iso8061toStringFormatted, iso8061toStringObject];
};

export default iso8061ToString;

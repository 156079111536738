import { ApiUrl, FontWeight } from '@/constants';
import Button from '@/newComponents/Button/Button';
import { ButtonColor } from '@/newComponents/Button/Button.utils';
import Card from '@/newComponents/Card/Card';
import FullWidthTeaserBlock from '@/newComponents/CmsBlocks/FullWidthTeaserBlock/FullWidthTeaserBlock';
import Divider from '@/newComponents/Divider/Divider';
import InnerCard from '@/newComponents/InnerCard/InnerCard';
import NoticeField from '@/newComponents/NoticeField/NoticeField';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Color, spacing } from '@/global/style/variables';

import { ComponentBlockType, type FullWidthTeaserBlock as FullWidthTeaserBlockType, type SavedeskPage } from '@/types';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { gtmSetVirtualPageView } from '@/global/gtm';
import { useAppContext } from '@/global/hooks/useAppContext';

import { getCmsTextWithValues } from '@/global/utils/Cms';
import iso8061ToString from '@/global/utils/Iso8061ToString';

import styles from './SavedeskOfferView.module.css';

const phoneNumberFormatting = (phoneNumber: string) => {
  const formattedMaskedNumber = phoneNumber.split('');
  formattedMaskedNumber.splice(4, 0, '-');
  formattedMaskedNumber.splice(7, 0, ' ');
  formattedMaskedNumber.splice(10, 0, ' ');
  return formattedMaskedNumber;
};

type SavedeskOffer = {
  accessToken: string;
  subscription: {
    maskedPhoneNumber: string;
    name: string;
    offerDuration: string;
    offerPrice: string;
    ordPrice: string;
    status: string;
    voucherExpirationDate: string;
  };
};

type SavedeskOfferViewProps = {
  offer?: SavedeskOffer;
  data: SavedeskPage;
  id: string;
  setCurrentPage: (page: string) => void;
};

const SavedeskOfferView = ({ offer, data, id, setCurrentPage }: SavedeskOfferViewProps) => {
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const pathname = usePathname();

  const { account } = useAppContext();
  const userId = account?.userId || null;

  useEffect(() => {
    gtmSetVirtualPageView(`${pathname}`, document.title);
  }, [pathname]);

  if (!offer) {
    return null;
  }

  const block: FullWidthTeaserBlockType = {
    $type: ComponentBlockType.FullWidthTeaserBlock,
    backgroundColor: '8D173F',
    header: data.heroHeader,
    subheader: data.heroSubheader,
    infoStickerHeader: getCmsTextWithValues(data.heroBubbleHeader, {
      offerPrice: offer.subscription.offerPrice,
    }),
    infoStickerPreamble1: getCmsTextWithValues(data.heroBubbleSubheader, {
      offerDuration: offer.subscription.offerDuration,
      ordPrice: offer.subscription.ordPrice,
    }),
    infoStickerPreamble2: getCmsTextWithValues(data.heroBubblePreamble, {
      ordPrice: offer.subscription.ordPrice,
    }),
    textPosition: 'Left',
    buttonType: ButtonColor.Primary,
  };

  const [timeExpiration] = iso8061ToString(offer.subscription.voucherExpirationDate);
  const formattedPhoneNumber = phoneNumberFormatting(offer.subscription.maskedPhoneNumber);

  const acceptOffer = async (offerId: string, accessToken: string) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${ApiUrl.webClientApi}/savedesk?s=${offerId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: accessToken }),
      });

      if (res.status === 204) {
        setCurrentPage('ConfirmationView');
      } else {
        setErrorMessage(`${data.submitErrorText}`);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(`${data.submitErrorText}`);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.contentWrapper}>
      <Divider />
      <FullWidthTeaserBlock userId={userId} data={block} />
      <article className={styles.contentContainer}>
        <Card data-testid="savedesk-offer-card">
          <InnerCard>
            <div className={styles.boxFrameUsp}>
              <SvgIcon icon="gift" size="small" color={Color.Hallon1} />
              <Typography type={TypographyType.Detail} fontWeight={FontWeight.Medium} text={data.boxHeader} />
            </div>
            <Divider marginY={spacing.x4} />
            <Typography type={TypographyType.ExpressiveL} text={data.header} align="center" />
            <Divider marginY={spacing.x2} />
            <div className={styles.infoFrame}>
              <SvgIcon icon="mobile" />
              <Divider width={spacing.x1} />
              <div className={styles.outerWrapper}>
                <div className={styles.innerWrapper}>
                  <Typography text={formattedPhoneNumber} fontWeight={FontWeight.SemiBold} />
                  <Typography text={offer.subscription.name} />
                </div>
                <div className={styles.innerWrapper}>
                  <Typography
                    text={`${offer.subscription.offerPrice} kr/mån`}
                    fontWeight={FontWeight.Medium}
                    color={Color.Hallon1}
                  />
                  <Typography text={`${offer.subscription.ordPrice}  kr/mån`} strikethrough />
                </div>
              </div>
            </div>
            <Divider marginY={spacing.x2} />
            <ul className={styles.uspList}>
              <li className={styles.uspListItem}>
                <Typography
                  text={getCmsTextWithValues(data.priceUsp, {
                    offerPrice: offer.subscription.offerPrice,
                    offerDuration: offer.subscription.offerDuration,
                    ordPrice: offer.subscription.ordPrice,
                  })}
                />
              </li>
              <li className={styles.uspListItem}>
                <Typography text={data.savedSurfUsp} />
              </li>
            </ul>
            {offer.subscription.status === 'CancellationPending' && (
              <>
                <NoticeField
                  data-testid="savedesk-offer-cancel-info-notice"
                  preset={NoticeFieldPreset.Notice}
                  useMaxLength={false}
                  text={data.cancelInfo}
                />
                <Divider marginY={spacing.x1} marginYSm={spacing.x2} />
              </>
            )}
            {offer.subscription.status === 'Active' && (
              <>
                <NoticeField
                  data-testid="savedesk-offer-cancel-info-reactivated-notice"
                  preset={NoticeFieldPreset.Notice}
                  useMaxLength={false}
                  text={data.cancelInfoReactivated}
                />
                <Divider marginY={spacing.x1} marginYSm={spacing.x2} />
              </>
            )}
            <div className={styles.lowerWrapper}>
              {errorMessage && (
                <>
                  <NoticeField
                    data-testid="savedesk-offer-error-notice"
                    preset={NoticeFieldPreset.Error}
                    useMaxLength={false}
                    text={errorMessage}
                  />
                  <Divider marginY={spacing.x1} marginYSm={spacing.x2} />
                </>
              )}
              <Button
                label={data.buttonText}
                type="submit"
                centered
                isLoading={loading}
                onClick={() => {
                  acceptOffer(id, offer.accessToken);
                }}
                data-testid="savedesk-offer-view-button"
              />
              <Divider marginY={spacing.x2} />
              <Typography
                type={TypographyType.Detail}
                text={getCmsTextWithValues(data.validUntilText, {
                  voucherExpirationDate: timeExpiration,
                })}
                color={Color.DarkGray1}
                align="center"
              />
            </div>
            <Divider marginY={spacing.x15} marginYSm={spacing.x25} />
            <Typography type={TypographyType.Detail} text={data.footnote} />
            <Divider marginY={spacing.x2} />
          </InnerCard>
        </Card>
      </article>
    </div>
  );
};

export default SavedeskOfferView;

import Button from '@/newComponents/Button/Button';
import Card from '@/newComponents/Card/Card';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Link } from '@/types';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { gtmSetVirtualPageView } from '@/global/gtm';
import { Color, spacing } from '@/global/style/variables';

import styles from '../SavedeskOfferView.module.css';

type SavedeskErrorViewProps = {
  data: {
    invalidHeader: string;
    invalidSubHeader: string;
    invalidBodyText: string;
    invalidButton: Link;
  };
};

const SavedeskErrorView = (props: SavedeskErrorViewProps) => {
  const { data } = props;
  const pathname = usePathname();

  useEffect(() => {
    gtmSetVirtualPageView(`${pathname}/invalid`, document.title);
  }, [pathname]);

  return (
    <>
      <Divider marginY={spacing.x5} />

      <Typography text={data.invalidHeader} type={TypographyType.HeadingL} color={Color.Hallon1} align="center" />
      <Divider marginY={spacing.x1} />
      <div className={styles.contentWrapper}>
        <article className={styles.contentContainer}>
          <Card data-testid="error-view">
            <Typography type={TypographyType.ExpressiveL} text={data.invalidSubHeader} align="center" />
            <Divider marginY={spacing.x25} />
            <Typography type={TypographyType.ExpressiveS} text={data.invalidBodyText} align="center" />
            <Divider marginY={spacing.x25} />
            <Button
              label={data.invalidButton.name}
              asLink
              href={data.invalidButton.url}
              data-testid="error-view-button"
            />

            <Divider />
          </Card>
        </article>
      </div>
    </>
  );
};

export default SavedeskErrorView;

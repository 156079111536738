import { ReactNode } from 'react';

import { Color } from '@/global/style/variables';
import Link from 'next/link';

import classNames from 'classnames';
import styles from './LinkNext.module.css';

export type LinkNextProps = {
  shrink?: boolean;
  color?: string;
  linkHref: string;
  children?: ReactNode;
  ['data-testid']?: string;
  tabIndex?: number;
  className?: string;
  shallow?: boolean;
  replace?: boolean;
  scroll?: boolean;
  onClick?: () => void;
  showPseudoClasses?: boolean;
};

const LinkNext = (props: LinkNextProps) => {
  const {
    color = Color.Blue,
    linkHref,
    children = null,
    shrink = false,
    tabIndex,
    className,
    shallow = false,
    replace = false,
    scroll = true,
    onClick,
    showPseudoClasses = false,
  } = props;

  const classes = classNames(styles.linkNext, className, {
    [styles.shrink]: shrink,
    [styles.showPseudoClasses]: showPseudoClasses,
  });

  const style = {
    '--link-color': color,
  } as React.CSSProperties;

  return (
    <Link
      style={style}
      className={classes}
      tabIndex={tabIndex}
      data-testid={props['data-testid']}
      color={color}
      href={linkHref}
      onClick={onClick}
      shallow={shallow}
      replace={replace}
      scroll={scroll}
    >
      {children}
    </Link>
  );
};

export default LinkNext;

import Button from '@/newComponents/Button/Button';
import Card from '@/newComponents/Card/Card';
import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { Image as ImageType, Link } from '@/types';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { gtmSetVirtualPageView } from '@/global/gtm';

import { getCmsTextWithValues } from '@/global/utils/Cms';

import LazyLoadingImage from '@/newComponents/LazyLoadingImage/LazyLoadingImage';
import HeadlineBlock from '@/newComponents/CmsBlocks/HeadlineBlock/HeadlineBlock';
import sharedStyles from '../SavedeskOfferView.module.css';

import styles from './ConfirmationView.module.css';

type ConfirmationViewProps = {
  data: {
    confirmationHeader: string;
    confirmationSubheader: string;
    confirmationBody: string;
    confirmationBoxHeader: string;
    confirmationTeaserHeader: string;
    confirmationTeaserText: string;
    confirmationTeaserImage: ImageType;
    confirmationTeaserButton: Link;
  };
  offer?: {
    subscription: {
      offerPrice: string;
      offerDuration: string;
      ordPrice: string;
    };
  };
};

const ConfirmationView = (props: ConfirmationViewProps) => {
  const { data, offer } = props;
  const pathname = usePathname();

  useEffect(() => {
    gtmSetVirtualPageView(`${pathname}/confirmed`, document.title);
  }, [pathname]);

  if (!offer) {
    return null;
  }

  return (
    <>
      <HeadlineBlock firstBlock data={{ header: data.confirmationHeader }} />
      <div className={sharedStyles.contentWrapper}>
        <article className={styles.contentContainer}>
          <Card data-testid="confirmation-view-card">
            <Typography type={TypographyType.ExpressiveL} text={data.confirmationSubheader} />
            <Divider marginY={spacing.x2} />
            <Typography
              type={TypographyType.ExpressiveS}
              text={getCmsTextWithValues(data.confirmationBody, {
                offerPrice: offer.subscription.offerPrice,
                offerDuration: offer.subscription.offerDuration,
                ordPrice: offer.subscription.ordPrice,
              })}
            />
            <Divider marginY={spacing.x3} />
            <div className={styles.outerFrame}>
              <div className={styles.boxFrameUsp}>
                <SvgIcon icon="mobile" size="small" color={Color.Hallon1} />
                <Typography
                  type={TypographyType.Detail}
                  fontWeight={FontWeight.Medium}
                  text={data.confirmationBoxHeader}
                />
              </div>
              <div className={styles.image}>
                <LazyLoadingImage
                  src={`${process.env.CMS_RETURN_URL}/${data.confirmationTeaserImage?.url}`}
                  title={data.confirmationTeaserImage?.name}
                  alt={data.confirmationTeaserImage?.name}
                  width="105"
                  height="105"
                />
              </div>
              <div className={styles.infoWrapper}>
                <Typography
                  className={styles.teaserHeader}
                  type={TypographyType.ExpressiveL}
                  text={data.confirmationTeaserHeader}
                  align="center"
                />
                <Divider margin={spacing.x05} />
                <Typography
                  className={styles.uspText}
                  type={TypographyType.Detail}
                  text={data.confirmationTeaserText}
                  align="center"
                />
              </div>
            </div>

            <Divider marginY={spacing.x25} />
            <Button
              label={data.confirmationTeaserButton.name}
              data-testid="confirmation-view-button"
              href={data.confirmationTeaserButton.url}
              asLink
            />
            <Divider marginY={spacing.x05} />
          </Card>
        </article>
      </div>
    </>
  );
};

export default ConfirmationView;

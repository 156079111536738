'use client';

import { useEffect } from 'react';
import { CmsPageTrackingSessionStorageKey } from '@/constants';
import { TrackingCmsProduct } from '@/types';

type TrackingProps = {
  tracking: TrackingCmsProduct | null;
};

export const Tracking = (props: TrackingProps) => {
  const { tracking } = props;

  useEffect(() => {
    sessionStorage.setItem(CmsPageTrackingSessionStorageKey, JSON.stringify(tracking));
  }, [tracking]);
  return null;
};

import Spinner, { SpinnerSize } from '@/newComponents/Spinner/Spinner';
import { Color } from '@/global/style/variables';
import styles from './SavedeskLoader.module.css';

const SavedeskLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Spinner size={SpinnerSize.Large} color={Color.Hallon1} />
    </div>
  );
};

export default SavedeskLoader;

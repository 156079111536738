import { FontWeight } from '@/constants';
import {
  CardContainer,
  ChildrenContainer,
  FooterContainer,
  HeaderContainer,
} from '@/newComponents/InnerCard/InnerCard.styled';
import Typography from '@/newComponents/Typography/Typography';

import { ReactNode } from 'react';

export type InnerCardProps = {
  header?: string;
  footer?: JSX.Element;
  children?: ReactNode;
  border?: boolean;
  className?: string;
  ['data-testid']?: string;
};

const InnerCard = (props: InnerCardProps) => {
  const { children, footer, header, border = true, className, 'data-testid': dataTestId = null } = props;
  return (
    <CardContainer $border={border} className={className} data-testid={dataTestId}>
      {header && (
        <HeaderContainer>
          <Typography text={header} fontWeight={FontWeight.SemiBold} />
        </HeaderContainer>
      )}
      <ChildrenContainer>{children}</ChildrenContainer>

      {footer && <FooterContainer>{footer}</FooterContainer>}
    </CardContainer>
  );
};

export default InnerCard;

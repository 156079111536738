'use client';

import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const CardContainer = styled.div<{ $border: boolean }>`
  width: 100%;
  background-color: ${Color.White};
  border-radius: ${NewBorderRadius.Small};
  min-width: 300px;
  font-weight: initial;

  ${({ $border }) => $border && `border: 1px solid ${Color.DarkGray2};`}
`;

export const ChildrenContainer = styled.div`
  padding: ${spacing.x2};

  ${above.sm`
    padding: ${spacing.x3};
  `}
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid ${Color.Gray2};
  padding: ${spacing.x15} ${spacing.x2};

  ${above.sm`
    padding: ${spacing.x3};
  `}
`;

export const FooterContainer = styled.div`
  text-align: center;
  border-top: 1px solid ${Color.Gray2};

  padding: ${spacing.x2};
  ${above.sm`
    padding: ${spacing.x3};
  `}
`;

'use client';

import type { ErrorBoundaryConfigType, ErrorPage } from '@/types';
import { ErrorBoundaryType } from '@/types';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import errorPageData from '@/pages/api/cms/500.json';

import ErrorPageWrapper from '@/pagewrappers/ErrorPage';

interface Props {
  children?: ReactNode;
  config?: ErrorBoundaryConfigType;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // error is needed by getDerivedStateFromError
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (typeof window !== 'undefined' && window.appInsights) {
      const { config } = this.props;
      window.appInsights.trackException({
        error,
        exception: {
          message: errorInfo.componentStack ?? '',
          url: window.location.href,
          lineNumber: 0,
          columnNumber: 0,
          error,
          typeName: config?.errorType || ErrorBoundaryType.Generic,
        },
      });

      window.appInsights.trackEvent({
        name: 'ERROR_BOUNDARY',
        properties: {
          errorType: config?.errorType || ErrorBoundaryType.Generic,
          url: window.location.href,
          message: errorInfo.componentStack,
          errorMessageReferenceKey: config?.errorMessageReferenceKey || 'UNKNOWN',
        },
      });
    }
  }

  render() {
    const { config, children } = this.props;
    if (this.state.hasError) {
      if (config?.component) {
        return config.component;
      }
      return <ErrorPageWrapper data={errorPageData as unknown as ErrorPage} />;
    }

    return children;
  }
}

export default ErrorBoundary;
